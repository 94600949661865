import cunt from "./assets/cunt.jpg";
import "./index.css";

function App() {
  return (
    <div className="App w-screen h-screen flex flex-col items-center justify-center text-center bg-[#E7DBC3] px-6 space-y-4">
      <iframe
        className="rounded-[2px] mt-4 md:mt-10 w-full md:w-1/4"
        title="C.U.N. Tuolumne"
        src="https://open.spotify.com/embed/episode/6X05SDPt6R7pfPh8wQofdO?utm_source=generator"
        height="152"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
      <div className="w-full md:w-1/4">
        <img src={cunt} alt="Cunt Logo" className="shadow-2xl" />
      </div>
    </div>
  );
}

export default App;
